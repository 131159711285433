<template>
  <z-card-modal
    :title="$t('reservation.data_validation_access')"
    :opened="opened"
    persistent
    width="600px"
  >
    <template #content>
      <data-validation
        has-fill-record
        :auth="auth"
        :contacts="contacts"
        :is-loading="isLoading"
        :is-loading-new="isLoadingOrders"
        :description="$t('reservation.data_validation_access_description')"
        @click:fnrh="onClickNewFnrh"
        @change="changeAuth({ validationMethod: $event })"
        @send="sendGuestPin"
        @back="$emit('back')"
      />
    </template>
  </z-card-modal>
</template>

<script>
import DataValidation from "../components/DataValidation.vue"
import { useDataValidation } from "../useDataValidation"

export default {
  components: { DataValidation },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    return useDataValidation(emit)
  },
}
</script>
