<!-- eslint-disable max-lines -->
<template>
  <div data-cy="home-page" class="home">
    <component
      :is="$vuetify.breakpoint.smAndDown ? 'home-mobile' : 'home-desktop'"
      :hotel="hotel"
      :reservation="reservation"
      :alerts="alerts"
      :completed-guests="completedGuests"
      :incompleted-guests="incompletedGuests"
      :render-send-notification-button="
        guestsForNotificate.length !== 0 ? true : false
      "
      :is-loading="isTryingAuth"
      :personalization-info="personalizationInfo.checkinInfo"
      :current-lang="capitalize(currentLang)"
      @finish="isModalOpened.finish = true"
      @alert:children="onOpenChildrenModal"
      @click:notificate="onClickNotificate"
      @click:edit="onSelectGuest"
      @click:open-info="isModalOpened.fnrh = true"
    />

    <guest-notification-modal
      :opened="isModalOpened.sendNotification"
      :is-loading="isLoadingNotification"
      :guests="guestsForNotificate"
      @change:guests="changeGuestsForNotificate"
      @click:close-modal="isModalOpened.sendNotification = false"
      @click:open-sent-modal="onClickSendNotification"
    />

    <sent-notification-modal
      :opened="isModalOpened.SentNotificationModal"
      @click:close-sent-modal="isModalOpened.SentNotificationModal = false"
    />

    <onboarding-modal
      :opened="isModalOpened.onboarding"
      @click:close="isModalOpened.onboarding = false"
      @click:open-info="isModalOpened.fnrh = true"
    />

    <fnrh-info-modal
      :opened="isModalOpened.fnrh"
      @click:close="isModalOpened.fnrh = false"
    />

    <has-children-modal
      :is-opened="isModalOpened.hasChildren"
      @close="isModalOpened.hasChildren = false"
    />

    <data-validation-modal
      data-cy="data-validation-modal"
      :opened="isModalOpened.dataValidation"
      @back="isModalOpened.dataValidation = false"
      @click:close="
        isModalOpened.dataValidation = false
        isModalOpened.tokenValidation = true
      "
    />

    <token-validation-modal
      data-cy="token-validation-modal"
      :opened="isModalOpened.tokenValidation"
      @click:close="isModalOpened.tokenValidation = false"
    />

    <share-with-guests-btn
      v-if="$vuetify.breakpoint.smAndDown && guestsForNotificate.length !== 0"
      :button-text="$t('reservation.share_btn')"
      @click:share="onClickNotificate"
    />

    <error-notification-modal
      :opened="isModalOpened.notificationError"
      :title="$t('reservation.send_notification_error')"
      :text="$t('reservation.send_notification_error_messager')"
      @click:close="isModalOpened.notificationError = false"
    />

    <nps-modal
      :opened="isModalOpened.nps"
      :nps="nps"
      :allow-comment="rules.allowNPSComment"
      :is-loading="isSendingNps"
      @change="changeNps"
      @click:evaluate="onSendNps"
      @click:close="onRedirectGuestControl"
    />

    <missing-guest-fill-modal
      :opened="isModalOpened.missingGuestFill"
      :has-notificate="
        reservation.guests
          .filter((guest) => !guest.masterGuest)
          .some((guest) => !guest.fnrhId)
      "
      @click:fill="isModalOpened.missingGuestFill = false"
      @click:notify="onClickNotificate"
      @click:close="isModalOpened.missingGuestFill = false"
    />

    <thanks-for-evaluate-modal
      :opened="isModalOpened.thanksNps"
      @click:ok="onRedirectGuestControl"
    />

    <finish-modal
      :opened="isModalOpened.finish"
      @confirm="onClickFinish"
      @close="isModalOpened.finish = false"
    />

    <in-checkin-reservation-modal
      :opened="isModalOpened.inCheckinReservation"
      @click:close="$router.push({ name: 'welcome-menu' })"
    />
  </div>
</template>

<script>
import { capitalize } from "lodash"
import { onMounted, reactive } from "vue"
import { useRouter } from "vue-router/composables"
import {
  useNamespacedActions,
  useNamespacedGetters,
  useNamespacedState,
} from "vuex-composition-helpers"
import { useNamespacedMutations } from "vuex-composition-helpers/dist"

import ExpiredReservationModal from "@/components/Alert/AlertTypes/ExpiredReservationModal.vue"
import { useStoreAction, useVuetify } from "@/composables"
import { useTracking } from "@/composables/useTracking"
import i18n from "@/i18n"
import { isToday } from "@/utils/date"

import InCheckinReservationModal from "../../components/Alert/AlertTypes/InCheckinReservationModal.vue"
import DataValidationModal from "../Auth/containers/DataValidationModal.vue"
import TokenValidationModal from "../Auth/containers/TokenValidationModal.vue"
import FinishModal from "./components/FinishModal.vue"
import FnrhInfoModal from "./components/FnrhInfoModal.vue"
import GuestNotificationModal from "./components/GuestNotificationModal.vue"
import HomeDesktop from "./components/HomeDesktop.vue"
import HomeMobile from "./components/HomeMobile.vue"
import MissingGuestFillModal from "./components/MissingGuestFillModal.vue"
import NpsModal from "./components/NpsModal.vue"
import OnboardingModal from "./components/OnboardingModal.vue"
import SentNotificationModal from "./components/SentNotificationModal.vue"
import ThanksForEvaluateModal from "./components/ThanksForEvaluateModal.vue"

export default {
  name: "Home",
  components: {
    HomeMobile,
    HomeDesktop,
    FinishModal,
    GuestNotificationModal,
    SentNotificationModal,
    DataValidationModal,
    TokenValidationModal,
    InCheckinReservationModal,
    OnboardingModal,
    FnrhInfoModal,
    ExpiredReservationModal,
    MissingGuestFillModal,
    NpsModal,
    ThanksForEvaluateModal,
  },
  // eslint-disable-next-line max-lines-per-function
  setup() {
    const router = useRouter()
    const vuetify = useVuetify()

    // Modais
    const isModalOpened = reactive({
      hasChildren: false,
      onboarding: false,
      fnrh: false,
      dataValidation: false,
      tokenValidation: false,
      sendNotification: false,
      notificationSuccess: false,
      notificationError: false,
      expiredReservation: false,
      missingGuestFill: false,
      inCheckinReservation: false,
      nps: false,
      thanksNps: false,
      finish: false,
      SentNotificationModal: false,
      authorizeDevice: false,
    })

    const { trackEvent } = useTracking()

    const { currentLang } = useNamespacedState("layout", ["currentLang"])
    // Flow
    const { start } = useNamespacedActions("flow/guest", ["start"])
    const { start: startReservationFlow } = useNamespacedActions(
      "flow/reservation",
      ["start"]
    )
    const { goToStepRoute } = useNamespacedMutations("flow/reservation", [
      "goToStepRoute",
    ])

    // Guest
    const { guest, isLoaded } = useNamespacedState("guest", [
      "guest",
      "isLoaded",
    ])

    const { saveFillData } = useStoreAction("guest", "saveFillData")

    const { showedOnBoardingModal } = useNamespacedState("guest", [
      "showedOnBoardingModal",
    ])
    const { setOnBoardingModal } = useNamespacedMutations("guest", [
      "setOnBoardingModal",
    ])

    // Auth
    const { isQualified } = useNamespacedState("guest/auth", ["isQualified"])
    const { tryAuthOrEditGuest, isLoading: isTryingAuth } = useStoreAction(
      "guest",
      "tryAuthOrEditGuest"
    )

    // NPS
    const { nps } = useNamespacedState("guest/nps", ["nps"])
    const { changeNps } = useNamespacedMutations("guest/nps", ["changeNps"])
    const { evaluateProduct, isLoading: isSendingNps } = useStoreAction(
      "guest/nps",
      "evaluateProduct"
    )

    // Helpers
    const { sendNotification } = useNamespacedActions("notifications", [
      "sendNotification",
    ])

    // Notification
    const { guestsForNotificate } = useNamespacedState("reservation", [
      "guestsForNotificate",
    ])
    const { loadGuestsToNotificate } = useNamespacedActions("reservation", [
      "loadGuestsToNotificate",
    ])
    const { changeGuestsForNotificate } = useNamespacedMutations(
      "reservation",
      ["changeGuestsForNotificate"]
    )
    const {
      sendNotificationToGuest,
      hasError: hasNotificationError,
      isLoading: isLoadingNotification,
    } = useStoreAction("reservation", "sendNotificationToGuest")

    // Company
    const { hotel, welcome, rules } = useNamespacedState("company", [
      "hotel",
      "welcome",
      "rules",
    ])
    const { redirectToGuestControl } = useStoreAction(
      "company",
      "redirectToGuestControl"
    )

    // Reservation
    const { alerts } = useNamespacedGetters("reservation", ["alerts"])
    const { reservation } = useNamespacedState("reservation", ["reservation"])
    const { completedGuests, incompletedGuests } = useNamespacedGetters(
      "reservation",
      ["completedGuests", "incompletedGuests"]
    )

    // Parameters
    const { personalizationInfo } = useNamespacedState("parameters", [
      "personalizationInfo",
    ])

    /**
     *
     */
    const onClickNotificate = () => {
      isModalOpened.missingGuestFill = false
      if (vuetify.breakpoint.smAndDown) {
        router.push({ name: "notification" })
      } else {
        isModalOpened.sendNotification = true
      }
    }

    /**
     * Envia notificações para guest
     */
    const onClickSendNotification = async () => {
      await sendNotificationToGuest()

      if (hasNotificationError.value) {
        sendNotification({
          message: i18n.tc("reservation.send_notification_error_messager"),
          duration: 3000,
          color: "var(--red-500)",
        })
        return
      }

      sendNotification({
        message: i18n.tc("reservation.sent_notification_modal_title"),
        duration: 3000,
        color: "primary",
      })

      isModalOpened.sendNotification = false
    }

    /**
     *
     */
    const onClickFinish = () => {
      isModalOpened.finish = false

      const allGuestsFilled = reservation.value.guests.every(
        (guest) => !!guest.fnrhId
      )

      const masterGuestFilled = reservation.value.guests.find(
        (guest) => guest.fnrhId && guest.masterGuest
      )

      if (
        allGuestsFilled ||
        (!rules.value.accompanyingGuest && masterGuestFilled)
      ) {
        isModalOpened.nps = true
      } else {
        isModalOpened.missingGuestFill = true
      }
    }

    /**
     * Seleciona guest para editar ficha FNRH
     */
    const onSelectGuest = async (guest) => {
      await tryAuthOrEditGuest(guest)

      if (guest.fnrhId == null)
        await saveFillData({ id: guest.id, formOrigin: "portal-v2" })

      if (isQualified.value && !isLoaded.value) {
        trackEvent("step_person_validation")
        if (vuetify.breakpoint.smAndDown) {
          router.push({ name: "data-validation" })
          return
        } else {
          isModalOpened.dataValidation = true
        }
      } else {
        start({
          isMainGuest: guest.masterGuest,
          isChild: false,
        })
      }
    }

    /**
     *
     */
    const onSendNps = async () => {
      await evaluateProduct(guest.value)
      isModalOpened.nps = false
      isModalOpened.thanksNps = true
    }

    /**
     *
     */
    const onRedirectGuestControl = () => {
      isModalOpened.nps = false
      if (welcome.value.enableRedirect) {
        redirectToGuestControl({
          isAnnonymous: true,
        })
      } else {
        router.push({ name: "reservation-finish" })
      }
    }

    /**
     *
     */
    const onClickNewFnrh = () => {
      start({
        isMainGuest: guest.value.masterGuest,
        isChild: false,
      })
    }

    /**
     *
     */
    const resetFlow = () => {
      startReservationFlow({ hasWelcomePage: false, hasReservationId: false })
      goToStepRoute()
    }

    /**
     *
     */
    const onOpenChildrenModal = () => {
      isModalOpened.hasChildren = true
      trackEvent("open_modal_children")
    }

    /**
     *
     */
    onMounted(async () => {
      if (vuetify.breakpoint.mdAndUp) {
        showedOnBoardingModal.value === true
          ? (isModalOpened.onboarding = false)
          : (isModalOpened.onboarding = true)

        setOnBoardingModal(true)
      }

      const checkinDate = new Date(reservation.value.checkinDate)

      if (rules.value.allowInCheckinFill === false && isToday(checkinDate)) {
        isModalOpened.inCheckinReservation = true
      }

      trackEvent("enter_home")
      loadGuestsToNotificate()
    })

    return {
      alerts,
      hotel,
      currentLang,
      reservation,
      completedGuests,
      incompletedGuests,
      nps,
      changeNps,
      guestsForNotificate,
      changeGuestsForNotificate,
      isModalOpened,
      isSendingNps,
      isLoadingNotification,
      isTryingAuth,
      showedOnBoardingModal,
      personalizationInfo,
      startReservationFlow,
      capitalize,
      onSendNps,
      onClickFinish,
      onClickNewFnrh,
      onClickNotificate,
      onClickSendNotification,
      onRedirectGuestControl,
      onSelectGuest,
      resetFlow,
      onOpenChildrenModal,
      rules,
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  max-height: calc(100% - 100px);
}
</style>
