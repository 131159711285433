<template>
  <z-card-modal
    :width="$vuetify.breakpoint.smAndDown ? '350px' : '485px'"
    :title="$t('modal.missing_guest_fill_modal.title')"
    persistent
    :opened="opened"
  >
    <template #content>
      <br />
      <p v-html="$t('modal.missing_guest_fill_modal.description_1')"></p>
      <br />
      <p class="mb-6">
        {{ $t("modal.missing_guest_fill_modal.description_2") }}
      </p>
      <div class="container-button">
        <z-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          class="btn-notify"
          :disabled="!hasNotificate"
          :text="$t('reservation.notify_guest')"
          @click="$emit('click:notify')"
        />
        <z-btn
          primary
          class="btn-fill-guest"
          :text="$t('reservation.fill_guest_record')"
          @click="$emit('click:fill')"
        />
        <z-btn
          v-if="$vuetify.breakpoint.smAndDown"
          class="btn-notify"
          :disabled="!hasNotificate"
          :text="$t('reservation.notify_guest')"
          @click="$emit('click:notify')"
        />
        <v-divider
          :class="{ 'grid-span-2': $vuetify.breakpoint.mdAndUp }"
        ></v-divider>
        <z-btn
          class="btn-cancel"
          :class="{ 'grid-span-2': $vuetify.breakpoint.mdAndUp }"
          :text="$t('reservation.back_button')"
          @click="$emit('click:close')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
import { ref } from "vue"

export default {
  props: {
    opened: {
      type: Boolean,
      default: true,
    },
    hasNotificate: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const iconSelected = ref(0)
    return {
      iconSelected,
    }
  },
}
</script>

<style lang="scss" scoped>
.icons {
  margin-right: 25px;
}

.container-button {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.btn-cancel {
  justify-self: end;
}

@media screen and (max-width: 960px) {
  .container-button {
    grid-template-columns: 1fr !important;
  }

  .btn-cancel {
    justify-self: unset;
  }

  .btn-evaluate {
    width: 100%;
    margin-left: 0;
    margin-bottom: 16px;
  }
}
</style>
