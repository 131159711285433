<template>
  <z-card-modal
    :title="$t('reservation.data_validation_access')"
    :opened="opened"
    persistent
    width="500px"
  >
    <template #content>
      <token-validation
        :auth="auth"
        :is-loading="isLoading"
        :is-timer-on="isTimerOn"
        :waiting-minutes="waitingMinutes"
        :validation-type="validationType"
        :contacts="contacts"
        @back="$emit('click:close')"
        @change="changeAuth({ pin: $event })"
        @resend="onResendCode"
        @finish="checkPinValidation"
      />
    </template>
  </z-card-modal>
</template>

<script>
import TokenValidation from "../components/TokenValidation.vue"
import { useTokenValidation } from "../useTokenValidation"

export default {
  components: { TokenValidation },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return useTokenValidation()
  },
}
</script>
