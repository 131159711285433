<template>
  <z-card-modal
    :title="$t('modal.thanks_evaluate_modal.title')"
    :width="$vuetify.breakpoint.smAndDown ? '350' : '487'"
    :opened="opened"
    persistent
  >
    <template #content>
      <div class="d-flex flex-column align-center mt-4">
        <z-icon color="var(--lightgreen-600)" size="80">
          $check_in_a_circle
        </z-icon>
        <p class="text-center my-6">
          {{ $t("modal.thanks_evaluate_modal.description") }}
        </p>
      </div>
      <div class="d-flex justify-end">
        <z-btn
          primary
          :text="$t('reservation.ok')"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
          @click="$emit('click:ok')"
        /></div></template
  ></z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
