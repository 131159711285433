<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <z-card-modal
    :width="$vuetify.breakpoint.smAndDown ? '350px' : '600px'"
    :title="$t('modal.nps_modal.title')"
    persistent
    :opened="opened"
  >
    <template #content>
      <p>{{ $t("modal.nps_modal.description") }}</p>
      <div class="d-flex justify-center my-4">
        <z-icon
          class="icons"
          xLarge
          :color="
            nps.rating === 1 || nps.rating === 0 ? 'var(--red-500)' : '#DDD'
          "
          @click="$emit('change', { rating: 1 })"
        >
          $sentiment_very_dissatisfied_rounded
        </z-icon>
        <z-icon
          class="icons"
          :color="
            nps.rating === 2 || nps.rating === 0 ? 'var(--orange-500)' : '#DDD'
          "
          xLarge
          @click="$emit('change', { rating: 2 })"
        >
          $sentiment_dissatisfied_rounded
        </z-icon>
        <z-icon
          class="icons"
          :color="
            nps.rating === 3 || nps.rating === 0 ? 'var(--yellow-500)' : '#DDD'
          "
          xLarge
          @click="$emit('change', { rating: 3 })"
        >
          $sentiment_neutral_rounded
        </z-icon>
        <z-icon
          class="icons"
          :color="
            nps.rating === 4 || nps.rating === 0
              ? 'var(--lightgreen-500)'
              : '#DDD'
          "
          xLarge
          @click="$emit('change', { rating: 4 })"
          >$sentiment_satisfied_rounded
        </z-icon>
        <z-icon
          class="icons"
          :color="
            nps.rating === 5 || nps.rating === 0
              ? 'var(--darkgreen-500)'
              : '#DDD'
          "
          xLarge
          @click="$emit('change', { rating: 5 })"
        >
          $sentiment_very_satisfied_rounded
        </z-icon>
      </div>
      <z-input-area
        v-if="allowComment"
        type="text"
        style="border-radius: var(--zoox-input-radius)"
        :label="$t('modal.nps_modal.label_text')"
        @input="$emit('change', { comment: $event })"
      />
      <div class="container-button">
        <z-btn
          class="btn-later"
          :text="$t('modal.nps_modal.button_cancel')"
          :disabled="isLoading"
          @click="$emit('click:close')"
        />
        <z-btn
          class="btn-evaluate"
          primary
          :disabled="nps.rating > 0 ? false : true"
          :is-loading="isLoading"
          :text="$t('modal.nps_modal.button_send')"
          @click="$emit('click:evaluate')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: true,
    },
    nps: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    allowComment: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.icons {
  margin-right: 25px;
}
.container-button {
  display: flex;
  justify-content: flex-end;
}
.btn-later {
  width: 150px;
}
.btn-evaluate {
  width: 150px;
  margin-left: 16px;
}
@media screen and (max-width: 960px) {
  .container-button {
    flex-direction: column-reverse;
    align-items: center;
  }
  .btn-later {
    width: 100%;
  }
  .btn-evaluate {
    width: 100%;
    margin-left: 0;
    margin-bottom: 16px;
  }
}
</style>
