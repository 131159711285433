<template>
  <div class="home-desktop-container">
    <div class="d-flex flex-column" style="gap: 10px">
      <reservation-infos
        :class="alerts.hasChildren || alerts.isIncompleted ? 'mb-1' : 'mb-0'"
        :hotel="hotel"
        :reservation-number="reservation.reservationNumber"
        :checkin-date="reservation.checkinDate"
        :checkout-date="reservation.checkoutDate"
      />
      <pass-alert
        v-if="alerts.hasChildren"
        data-cy="children-alert"
        clickable
        :class="alerts.isIncompleted ? 'mb-1' : 'mb-0'"
        :text="$t('alerts.has_children_alert')"
        :description="$t('alerts.know_more')"
        @click="$emit('alert:children')"
      />
      <pass-alert
        v-if="alerts.isIncompleted"
        data-cy="incomplete-alert"
        :text="$t('alerts.incompleted_guests_alert')"
        :description="$t('alerts.incompleted_guests_alert_description')"
        @click="$emit('alert:incomplete')"
      />
    </div>

    <z-card style="heigth: 100%">
      <template #content>
        <div
          v-if="personalizationInfo.useDefault === true"
          class="carousel-wrapper"
        >
          <div ref="gliderWrapper" class="glider-wrapper">
            <div class="carousel-container">
              <img
                style="max-width: 200px"
                src="@/assets/illustrations/onboarding-fnrh.svg"
              />

              <h4 class="text-center my-6">
                {{ $t("reservation.dont_loose_time") }}
              </h4>
              <p class="text-center body-text">
                <span>{{ $t("reservation.a") }} </span
                ><span
                  ><a
                    style="text-decoration: underline"
                    @click="$emit('click:open-info')"
                    >{{ $t("reservation.national_guest_registration_form") }}
                  </a></span
                >{{ $t("reservation.nacional_records") }}
              </p>
            </div>
          </div>
          <div ref="dots" class="dots"></div>
        </div>
        <div v-else class="d-flex align-center justify-center flex-column">
          <img :src="personalizationInfo.image" />
          <div
            :class="classes.div"
            v-html="personalizationInfo.text[currentLang]"
          ></div>
        </div>
      </template>
    </z-card>

    <z-card width="100%" style="grid-row: span 2; position: relative">
      <template #content>
        <h3 class="my-3">{{ $t("reservation.guest_list") }}</h3>
        <template v-if="incompletedGuests.length > 0">
          <div class="d-flex align-center">
            <h4 class="mb-3">{{ $t("reservation.waiting_sign") }}</h4>
            <div class="separator-line" />
          </div>
          <p class="body-text mb-5">
            {{ $t("reservation.tips_guest") }}
          </p>
          <div class="card-guest-container">
            <template v-for="(guest, i) in incompletedGuests">
              <guest-card
                v-if="!guest.fnrhId"
                :key="i"
                :is-main-guest="guest.masterGuest"
                :name="guest.name + ' ' + guest.lastName"
                :contact="guest.email"
                :avatar="guest.photoUrl || undefined"
                :completed="guest.completed"
                :was-notified="guest.transmissions?.length > 0"
                :render-send-notification-button="renderSendNotificationButton"
                :is-loading="
                  isLoading && $store.state.guest.guest.id === guest.id
                "
                @click:guest-card-edit="$emit('click:edit', guest)"
                @click:guest-card-notification="
                  $emit('click:notificate', guest)
                "
              />
            </template>
          </div>
        </template>

        <template v-if="completedGuests.length > 0">
          <div class="d-flex align-center mb-3" style="max-height: 100px">
            <h4 class="my-3">{{ $t("reservation.completed_sign") }}</h4>
            <div class="separator-line" />
          </div>
          <div class="card-guest-container">
            <template v-for="(guest, i) in completedGuests">
              <guest-card
                :key="i"
                :is-main-guest="guest.masterGuest"
                :name="guest.name + ' ' + guest.lastName"
                :contact="guest.email"
                :avatar="guest.photoUrl"
                :completed="true"
                :render-send-notification-button="renderSendNotificationButton"
                :is-loading="
                  isLoading && $store.state.guest.guest.id === guest.id
                "
                @click:guest-card-edit="$emit('click:edit', guest)"
              />
            </template>
          </div>
        </template>
        <div style="position: absolute; right: 15px; bottom: 15px">
          <z-btn
            :disabled="isLoading"
            :primary="reservation.guests.every((guest) => !!guest.fnrhId)"
            :text="$t('reservation.finalized_fill')"
            @click="$emit('finish')"
          />
        </div>
      </template>
    </z-card>
  </div>
</template>

<script>
export default {
  props: {
    hotel: {
      type: Object,
      default: () => {},
    },
    reservation: {
      type: Object,
      default: () => {},
    },
    completedGuests: {
      type: Array,
      default: () => [],
    },
    incompletedGuests: {
      type: Array,
      default: () => [],
    },
    alerts: {
      type: Object,
      required: true,
    },
    renderSendNotificationButton: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    personalizationInfo: {
      type: Object,
      default: () => {},
    },
    currentLang: {
      type: String,
      default: "Pt",
    },
  },
}
</script>

<style module="classes" lang="scss">
.div {
  margin: 10px auto;
  h1 {
    text-align: center !important;
    font-size: 1.2rem !important;
    font-weight: 300 !important;
    letter-spacing: 0.15px !important;
  }
  p {
    font-size: 0.9rem;
    line-height: 120%;
    letter-spacing: 0.15px !important;
    font-weight: 400;
    text-align: center !important;
  }
}
</style>

<style lang="scss" scoped>
.home-desktop-container {
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  grid-gap: 20px;
  width: 100%;
  padding: 10px;
  height: 100%;
}
.card-guest-container {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 15px;
}
.separator-line {
  border-top: 1px solid var(--black-100);
  height: 1px;
  width: 100%;
  margin-left: 15px;
}
h4 {
  white-space: nowrap;
}
.carousel-wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  .glider-wrapper {
    width: 100%;
    height: 95%;
  }
}
.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100% !important;
}
</style>
