<template>
  <z-card-modal :opened="opened" persistent width="463px" :overlay-opacity="1">
    <template #content>
      <div data-cy="expired-reservation-modal">
        <h5 class="text-uppercase my-2">
          {{ renderMessageError.title }}
        </h5>
        <p class="body-text body-2 my-3">
          {{ renderMessageError.description }}
        </p>

        <div class="modal-btn-container">
          <z-btn
            class="modal-btn"
            primary
            width="100%"
            :text="$t('alerts.expired_reservation_button')"
            @click="$emit('click:close')"
          />
        </div>
      </div>
    </template>
  </z-card-modal>
</template>

<script>
import { computed } from "vue"

import { STATUS_RESERVATION } from "@/consts"
import i18n from "@/i18n"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    resStatus: {
      type: Number,
      default: 6,
    },
  },
  setup(props) {
    const renderMessageError = computed(() => {
      switch (props.resStatus) {
        case STATUS_RESERVATION.CHECKOUT:
          return {
            title: i18n.tc("alerts.checkout_reservation_title"),
            description: i18n.tc("alerts.checkout_reservation_description"),
          }
        case STATUS_RESERVATION.PENDING:
          return {
            title: i18n.tc("alerts.pending_reservation_title"),
            description: i18n.tc("alerts.pending_reservation_description"),
          }
        case STATUS_RESERVATION.NO_SHOW:
          return {
            title: i18n.tc("alerts.noshow_reservation_title"),
            description: i18n.tc("alerts.noshow_reservation_description"),
          }
        case STATUS_RESERVATION.CANCELLED:
          return {
            title: i18n.tc("alerts.expired_reservation_title"),
            description: i18n.tc("alerts.expired_reservation_description"),
          }
        default:
          //CANCELLED - EXPIRED
          return {
            title: i18n.tc("alerts.expired_reservation_title"),
            description: i18n.tc("alerts.expired_reservation_description"),
          }
      }
    })

    return {
      renderMessageError,
    }
  },
}
</script>

<style lang="scss" scoped>
.checklist-img {
  text-align: center;
}

.modal-btn {
  margin-top: 20px;
}

@media screen and (min-width: 786px) {
  .modal-btn-container {
    text-align: end;
  }

  .modal-btn {
    width: 155px !important;
  }
}
</style>
