<template>
  <z-card-modal
    data-cy="onboarding-modal"
    width="700px"
    height="440px"
    :opened="opened"
    class="card-container"
  >
    <template #content>
      <span class="close-icon" @click="$emit('click:close')"
        ><z-icon> $close_rounded </z-icon>
      </span>

      <section class="main-content-onboarding">
        <div class="checklist-img">
          <img
            v-if="$vuetify.breakpoint.smAndDown"
            class="my-3"
            src="@/assets/images/logo-hotel-dark.png"
          />
          <illustration-onboarding></illustration-onboarding>
        </div>

        <div class="text-content">
          <h4 class="body-text text-center">
            {{ $t("onboarding.title") }}
          </h4>
          <p class="body2 text-center my-3 px-4">
            {{ $t("onboarding.subtitle") }}
          </p>
          <ul v-for="list in listItems" :key="list.text">
            <li class="d-flex align-center mb-2">
              <z-icon class="pass-alert-icon mx-2" size="18px" color="green">
                $check_in_a_circle
              </z-icon>
              <p class="mb-0 body2">{{ list.text }}</p>
            </li>
          </ul>
        </div>
      </section>
      <div class="btn-container">
        <z-btn
          class="letsgo-btn my-4"
          primary
          width="100%"
          :text="$t('onboarding.letsgo_btn')"
          @click="$emit('click:close')"
        />
        <z-btn
          class="fnrh-btn"
          width="100%"
          :text="$t('onboarding.fnrh_btn')"
          @click="$emit('click:open-info')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
import { computed } from "vue"

import i18n from "@/i18n"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const listItems = computed(() => {
      return [
        { text: i18n.tc("onboarding.text_list_1") },
        { text: i18n.tc("onboarding.text_list_2") },
        { text: i18n.tc("onboarding.text_list_3") },
        // { text: i18n.tc("onboarding.text_list_4") },
      ]
    })

    return {
      listItems,
    }
  },
}
</script>

<style lang="scss" scoped>
.checklist-img {
  text-align: center;
}

.close-icon {
  position: absolute;
  right: 0;
  margin: 0 10px;
  cursor: pointer;
}

.card-container {
  width: 100% !important;
  height: 100% !important;
}

.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

//desktop
@media screen and (min-width: 786px) {
  .modal-btn-container {
    text-align: end;
  }

  .btn-container {
    flex-direction: row-reverse;
    justify-content: end;
  }

  .letsgo-btn {
    width: 150px !important;
    margin: 0 15px;
  }

  .fnrh-btn {
    width: 255px !important;
  }

  .checklist-img {
    flex-direction: row;
    width: 50%;
  }

  .main-content-onboarding {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 50px;
  }

  .text-content {
    width: 50%;
    padding: 0 20px;
    h4 {
      margin-bottom: 30px;
    }
    ul {
      padding-left: 0 !important;
    }
  }
}
</style>
