/**
 * Verifica se o UUID é valido
 *
 * @param {Date} date Data a ser comparada
 * @returns {boolean} isToday
 */
export function isToday(date) {
  const today = new Date()

  return (
    date.getUTCDate() === today.getUTCDate() &&
    date.getUTCMonth() === today.getUTCMonth() &&
    date.getUTCFullYear() === today.getUTCFullYear()
  )
}
