<template>
  <z-card-modal
    class="modal"
    :title="$t('reservation.notify_guest')"
    :opened="opened"
    width="375px"
    persistent
  >
    <template #content>
      <p
        class="body2 mt-3"
        v-html="$t('reservation.guest_notification_main_text')"
      ></p>

      <z-scrollbar class="card-scrollbar my-3" suppress-scroll-x>
        <z-card
          v-for="(guest, index) in guests"
          :key="guest.id"
          :title="guest.fullName"
          width="600"
        >
          <template #content>
            <section class="guest-container mt-3">
              <z-input
                data-cy="input-email"
                :label="$t('reservation.email_input_label')"
                @input="
                  $emit('change:guests', { [`[${index}].email`]: $event })
                "
              />
              <z-phone-input
                v-if="rules.smsSendingEnabled"
                is-mobile
                data-cy="input-phone"
                :label="$t('reservation.phone_input_label')"
                :current-country="guest.currentCountry"
                :value="guest.mobile"
                :countries="countries"
                @change:country="
                  $emit('change:guests', {
                    [`[${index}].currentCountry`]: $event,
                  })
                "
                @input="
                  $emit('change:guests', { [`[${index}].mobile`]: $event })
                "
              />
              <div class="card-underline"></div>
            </section>
          </template>
        </z-card>
      </z-scrollbar>

      <section class="btn-container">
        <z-btn
          :text="$t('reservation.cancel_button')"
          @click="$emit('click:close-modal')"
        />
        <z-btn
          data-cy="send-notification-btn"
          primary
          :is-loading="isLoading"
          :text="$t('reservation.notify_guest')"
          @click="$emit('click:open-sent-modal')"
        />
      </section>
    </template>
  </z-card-modal>
</template>

<script>
import countries from "@zoox-ui/data/countries.json"
import { useNamespacedState } from "vuex-composition-helpers/dist"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    guests: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { rules } = useNamespacedState("company", ["rules"])

    return { countries, rules }
  },
}
</script>

<style lang="scss" scoped>
.card-scrollbar {
  max-height: 440px;
}

.btn-container {
  display: flex;
  justify-content: space-evenly;
}

.card-underline {
  border-top: 1px solid var(--black-100);
}
</style>
