<template>
  <z-card-modal
    persistent
    :title="$t('reservation.finalize_guest_list')"
    :opened="opened"
    width="470px"
  >
    <template #content>
      <div class="finish-modal">
        <p>
          {{ $t("reservation.finish_modal[0]") }}
        </p>
        <p>
          {{ $t("reservation.finish_modal[1]") }}
        </p>
        <div class="buttons-container">
          <z-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            width="130"
            :text="$t('reservation.cancel_button')"
            @click="$emit('close')"
          />
          <z-btn
            primary
            :class="{ 'ml-2': $vuetify.breakpoint.mdAndUp }"
            text="OK"
            :width="$vuetify.breakpoint.mdAndUp ? '130' : '100%'"
            @click="$emit('confirm')"
          />
          <z-btn
            v-if="$vuetify.breakpoint.smAndDown"
            :text="$t('reservation.cancel_button')"
            @click="$emit('close')"
          />
        </div>
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
.buttons-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.finish-modal {
  display: grid;
  row-gap: 15px;
  margin-top: 15px;
}

@media screen and (max-width: 960px) {
  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    button {
      width: 50% !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .buttons-container {
    display: grid;
    justify-content: unset;
    margin-top: 10px;
    row-gap: 10px;

    button {
      width: 100% !important;
    }
  }
}
</style>
