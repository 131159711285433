<template>
  <div class="home-mobile">
    <pass-alert
      v-if="alerts.hasChildren"
      data-cy="children-alert"
      clickable
      :text="$t('alerts.has_children_alert')"
      :description="$t('alerts.has_children_alert_description')"
      @click="$emit('alert:children')"
    />
    <pass-alert
      v-if="alerts.isIncompleted"
      data-cy="incomplete-alert"
      :text="$t('alerts.incompleted_guests_alert')"
      :description="$t('alerts.incompleted_guests_alert_description')"
      @click="$emit('alert:incomplete')"
    />
    <reservation-infos
      :reservation-number="reservation.reservationNumber"
      :hotel="hotel"
      :checkin-date="reservation.checkinDate"
      :checkout-date="reservation.checkoutDate"
    />

    <div>
      <h3 class="mb-3">{{ $t("reservation.guest_list") }}</h3>
      <p class="body-text mb-5">
        {{ $t("reservation.tips_guest") }}
      </p>
      <template v-if="incompletedGuests.length > 0">
        <h4 class="mb-3">{{ $t("reservation.waiting_sign") }}</h4>
        <div
          v-for="(guest, i) in incompletedGuests"
          :key="i + 'incomplete'"
          class="mb-3"
        >
          <guest-card
            :is-main-guest="guest.masterGuest"
            :name="guest.name + ' ' + guest.lastName"
            :contact="guest.email"
            :avatar="guest.photoUrl || undefined"
            :completed="false"
            :guest-card-selected="guestSelectedIncomplete"
            :index="i"
            :is-loading="isLoading"
            :was-notified="guest.transmissions?.length > 0"
            @click:guest-card="guestSelectedIncomplete = i"
            @click:guest-card-edit="$emit('click:edit', guest)"
            @click:guest-card-fill="$emit('click:edit', guest)"
          />
        </div>
      </template>

      <template v-if="completedGuests.length > 0">
        <h4 class="mb-3">{{ $t("reservation.completed_sign") }}</h4>
        <div
          v-for="(guest, i) in completedGuests"
          :key="i + 'completed'"
          class="mb-3"
        >
          <guest-card
            :is-main-guest="guest.masterGuest"
            :name="guest.name + ' ' + guest.lastName"
            :contact="guest.email"
            :avatar="guest.photoUrl || undefined"
            :completed="true"
            :guest-card-selected="guestCardSelected"
            :index="i"
            :is-loading="isLoading"
            @click:guest-card="guestCardSelected = i"
            @click:guest-card-edit="$emit('click:edit', guest)"
            @click:guest-card-fill="$emit('click:edit', guest)"
          />
        </div>
      </template>
    </div>
    <z-btn
      width="100%"
      :disabled="isLoading"
      :primary="reservation.guests.every((guest) => !!guest.fnrhId)"
      :text="$t('reservation.finalized_fill')"
      @click="$emit('finish')"
    />
  </div>
</template>

<script>
import { ref } from "vue"
export default {
  props: {
    hotel: {
      type: Object,
      default: () => {},
    },
    reservation: {
      type: Object,
      default: () => {},
    },
    completedGuests: {
      type: Array,
      default: () => [],
    },
    incompletedGuests: {
      type: Array,
      default: () => [],
    },
    alerts: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const guestCardSelected = ref(-1)
    const guestSelectedIncomplete = ref(-1)

    return { guestCardSelected, guestSelectedIncomplete }
  },
}
</script>

<style lang="scss" scoped>
.guest-card {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-template-rows: 1.2fr 1fr 1fr;
}

.home-mobile {
  display: grid;
  row-gap: 15px;
  padding-top: 24px;
}
</style>
