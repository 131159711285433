<template>
  <z-card-modal
    :opened="opened"
    persistent
    :title="$t('reservation.dont_recognize_data_to_validate')"
    :width="$vuetify.breakpoint.smAndDown ? '350' : '500'"
  >
    <template #content>
      <div class="main-content-modal my-2">
        <p class="body-text">
          {{ $t("reservation.if_dont_recognize_data") }}
        </p>
        <section class="section">
          <z-icon class="mr-2" color="var(--lightgreen-600)">
            $check_circle_outline_rounded</z-icon
          >
          <p class="body-text">
            {{ $t("reservation.reservation_turism") }}
          </p>
        </section>
        <section class="section">
          <z-icon class="mr-2" color="var(--lightgreen-600)">
            $check_circle_outline_rounded</z-icon
          >
          <p class="body-text">
            {{ $t("reservation.third_part_solicitation") }}
          </p>
        </section>
        <section class="section">
          <z-icon class="mr-2" color="var(--lightgreen-600)">
            $check_circle_outline_rounded
          </z-icon>
          <p class="body-text">
            {{ $t("reservation.corporative_number") }}
          </p>
        </section>
        <p class="body-text body-bold">
          {{ $t("reservation.if_profile_list") }}
        </p>
        <div class="button-container">
          <z-btn
            primary
            data-cy="close-modal-btn"
            :disabled="isLoading"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '130px'"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-3' : 'ml-3'"
            :text="$t('reservation.understood')"
            @click="$emit('click:close')"
          />
          <z-btn
            :text="$t('reservation.want_fill_new_record')"
            :is-loading="isLoading"
            @click="$emit('click')"
          />
        </div>
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content-modal {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 15px;
}

.button-container {
  display: flex;
  flex-direction: column;
}

.section {
  display: flex;
}
@media screen and (min-width: 960px) {
  .button-container {
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>
