import { computed } from "vue"
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useCountdownTimer, useStoreAction } from "@/composables"
import { Time } from "@/consts"
import i18n from "@/i18n"
import { useStore } from "@/store/index"

export function useTokenValidation() {
  const store = useStore()

  // Guest
  const { guest } = useNamespacedState("guest", ["guest"])
  const { getGuest, isLoading: isGuestLoading } = useStoreAction(
    "guest",
    "getGuest"
  )

  // Guest Auth
  const { auth, contacts } = useNamespacedState("guest/auth", [
    "auth",
    "contacts",
  ])
  const { changeAuth } = useNamespacedMutations("guest/auth", ["changeAuth"])
  const { sendPin } = useStoreAction("guest/auth", "sendPin")
  const {
    verifyPin,
    isLoading: isVerifyPinLoading,
    hasError: isPinInvalid,
  } = useStoreAction("guest/auth", "verifyPin")

  // helpers
  const { waitingMinutes, onWaitingTimer, isTimerOn } = useCountdownTimer(90)
  const { sendNotification } = useNamespacedActions("notifications", [
    "sendNotification",
  ])

  const checkPinValidation = async () => {
    await verifyPin(guest.value)

    if (isPinInvalid.value) {
      sendNotification({
        message: i18n.tc("reservation.invalid_token_message"),
        duration: 2000,
        confirmationText: "Ok",
        color: "red",
      })
    } else {
      await getGuest()
      store.dispatch("flow/guest/start", {
        isMainGuest: guest.value.masterGuest,
        isChild: false,
      })
    }
  }

  const onResendCode = async () => {
    onWaitingTimer()
    await sendPin(guest.value)
    sendNotification({
      message: i18n.tc("reservation.resend_code_message"),
      duration: 4 * Time.Seconds,
      color: "blue",
    })
  }

  const isLoading = computed(() => {
    if (isVerifyPinLoading.value || isGuestLoading.value) {
      return true
    } else {
      return false
    }
  })

  const validationType = computed(() => {
    return auth?.value?.validationMethod?.includes("@") ? "email" : "SMS"
  })

  return {
    auth,
    contacts,
    changeAuth,
    isLoading,
    validationType,
    waitingMinutes,
    isTimerOn,
    verifyPin,
    checkPinValidation,
    onResendCode,
  }
}
