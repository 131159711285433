<template>
  <div class="main-validation-container" data-cy="data-validation-page">
    <div class="d-flex flex-column mb-2">
      <h2 v-if="title" class="text-center" style="color: var(--primary)">
        {{ title }}
      </h2>
      <p
        class="body-text my-5"
        :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
      >
        {{ description }}
      </p>
      <z-radio-group
        class="radio-group"
        :items="contacts"
        :value="auth.validationMethod"
        @change="$emit('change', $event)"
      >
        <template #item="{ item }">
          <div class="d-flex" data-cy="radio-group">
            <z-icon color="#000">
              {{
                item.type === "mobile"
                  ? "$message_left"
                  : "$mail_outline_rounded"
              }}
            </z-icon>

            <div class="d-flex align-center mx-2">
              <z-country-flag
                v-if="item.type === 'mobile'"
                class="mr-2"
                rounded
                :country="item.country"
              />
              <div class="d-flex flex-column">
                <p class="mb-0">
                  {{
                    item.type === "mobile"
                      ? $t("reservation.send_sms")
                      : $t("reservation.send_email")
                  }}
                </p>
                <p class="my-1" style="color: #000">
                  {{ item.value }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </z-radio-group>
    </div>

    <template v-if="$vuetify.breakpoint.mdAndUp">
      <div class="d-flex justify-end align-center" style="width: 100%">
        <z-btn
          class="my-5 mr-3"
          width="130px"
          :disabled="isLoading || isLoadingNew"
          :text="$t('reservation.cancel_button')"
          @click="$emit('back')"
        />
        <z-btn
          primary
          width="130px"
          data-cy="send-pin-btn"
          :is-loading="isLoading"
          :disabled="auth.validationMethod === '' || isLoadingNew"
          :text="$t('reservation.advance_button')"
          @click="$emit('send')"
        />
      </div>
      <div
        v-if="hasFillRecord"
        style="border-top: 1px solid var(--black-100); width: 100%"
      />
      <div
        v-if="hasFillRecord"
        class="d-flex align-center justify-space-around"
        style="width: 100%"
      >
        <a
          v-if="hasFillRecord"
          data-cy="open-modal-btn"
          class="text-decoration-underline my-5"
          :is-loading="isLoadingNew"
          @click="isDontRecognizeModalOpen = true"
        >
          {{ $t("reservation.dont_recognize_data") }}</a
        >
        <z-btn
          v-if="hasFillRecord"
          class="my-5"
          :disabled="isLoading"
          :is-loading="isLoadingNew"
          :text="$t('reservation.want_fill_new_record')"
          @click="$emit('click:fnrh')"
        />
      </div>
    </template>

    <div v-else class="d-flex flex-column align-center" style="width: 100%">
      <a
        v-if="hasFillRecord"
        data-cy="open-modal-btn"
        class="text-decoration-underline my-5"
        @click="isDontRecognizeModalOpen = true"
      >
        {{ $t("reservation.dont_recognize_data") }}</a
      >
      <z-btn
        primary
        width="100%"
        data-cy="send-pin-btn"
        :is-loading="isLoading"
        :disabled="auth.validationMethod === '' || isLoadingNew"
        :text="$t('reservation.advance_button')"
        @click="$emit('send')"
      />

      <z-btn
        width="100%"
        class="my-5"
        :disabled="isLoading || isLoadingNew"
        :text="backBtnText || $t('reservation.back_guest_list')"
        @click="$emit('back')"
      />
      <div style="border-top: 1px solid var(--black-100); width: 100%" />
      <z-btn
        v-if="hasFillRecord"
        class="my-5"
        width="100%"
        :disabled="isLoading"
        :is-loading="isLoadingNew"
        :text="$t('reservation.want_fill_new_record')"
        @click="$emit('click:fnrh')"
      />
    </div>
    <dont-recognize-modal
      v-if="hasFillRecord"
      data-cy="dont-recognize-modal"
      :opened="isDontRecognizeModalOpen"
      :is-loading="isLoadingNew"
      @click:close="isDontRecognizeModalOpen = false"
      @click="$emit('click:fnrh')"
    />
  </div>
</template>

<script>
import { ref } from "vue"

import DontRecognizeModal from "./DontRecognizeDataModal.vue"

export default {
  components: { DontRecognizeModal },
  props: {
    auth: {
      type: Object,
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingNew: {
      type: Boolean,
      default: false,
    },
    hasFillRecord: {
      type: Boolean,
      default: false,
    },
    backBtnText: {
      type: String,
      default: "",
    },
  },
  setup() {
    const isDontRecognizeModalOpen = ref(false)

    return { isDontRecognizeModalOpen }
  },
}
</script>

<style lang="scss" scoped>
.main-validation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
