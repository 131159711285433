import { useRouter } from "vue-router/composables"
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useStoreAction, useVuetify } from "@/composables"

export function useDataValidation(emit = () => {}) {
  const { guest } = useNamespacedState("guest", ["guest"])
  const { start } = useNamespacedActions("flow/guest", ["start"])

  const { auth, contacts } = useNamespacedState("guest/auth", [
    "auth",
    "contacts",
  ])
  const { changeAuth } = useNamespacedMutations("guest/auth", ["changeAuth"])
  const { sendPin, isLoading, hasError } = useStoreAction(
    "guest/auth",
    "sendPin"
  )

  const { getOrderByReservationNumber, isLoading: isLoadingOrders } =
    useStoreAction("guest", ["getOrderByReservationNumber"])

  const router = useRouter()
  const vuetify = useVuetify()

  const sendGuestPin = async () => {
    await sendPin(guest.value)

    if (vuetify.breakpoint.smAndDown) {
      router.push({ name: "token-validation" })
    } else {
      emit("click:close")
    }
  }

  const onClickNewFnrh = async () => {
    if (guest?.value?.masterGuest) await getOrderByReservationNumber()
    start({ isMainGuest: guest.value.masterGuest })
  }

  return {
    isLoading,
    hasError,
    changeAuth,
    auth,
    contacts,
    isLoadingOrders,
    sendGuestPin,
    onClickNewFnrh,
  }
}
